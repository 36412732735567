
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCttocmsVBcR-RCo3Oe78u0McJ_qwLxgg4",
  authDomain: "jazzzo.firebaseapp.com",
  projectId: "jazzzo",
  storageBucket: "jazzzo.appspot.com",
  messagingSenderId: "286561491197",
  appId: "1:286561491197:web:35bb0d41f93517c94cc694"
};

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);

export const storage = getStorage(firebase);

