import { collection, doc, DocumentData, getCountFromServer, getDoc, getDocs, limit, onSnapshot, orderBy, query, QueryDocumentSnapshot, startAfter, updateDoc, where } from "firebase/firestore";
import { ReactNode, useEffect, useState } from "react";
import { ArrowDown, ArrowUp, Edit, MoreHorizontal, MoreVertical, RefreshCw, Settings as IconSettings } from "react-feather";
import { TagsInput } from "react-tag-input-component";
import LogoX from "../assets/logo-x.svg"

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/keyboard";

import Placeholder from "../assets/placeholder.svg";
import { db } from "../firebase/firebase";
import { formattedDate, lastDayOfMonth } from "../helper/dateHelper";
import { defaultSettings, ImageData, Settings, Tag, User } from "../types";
import Button from "./Button";
import Overlay from "./Overlay";
import Slider from "./Swiper";
import Switcher from "./Switcher";
import Toggle from "./Toggle";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Gallery = () => {

  const [user, setUser] = useState<User>()

  const [images, setImages] = useState<ImageData[]>([])
  const [imageLimit, setImageLimit] = useState(5)
  const [imageCount, setImageCount] = useState<number>(0)
  const [allImagesLoaded, setAllImagesLoaded] = useState(false)
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData, DocumentData>>()
  const [lastVisibleLength, setLastVisibleLength] = useState<number>(imageLimit)

  const [galleryDirection, setGalleryDirection] = useState<"ltr" | "ttb">("ltr")

  const [galleryIsOpen, setGalleryIsOpen] = useState(false)
  const [initialSlide, setInitialSlide] = useState(0)

  const [editImageIsOpen, setEditImageIsOpen] = useState(false)
  const [editImage, setEditImage] = useState<ImageData>()
  const [editTitle, setEditTitle] = useState("")
  const [editDescription, setEditDescription] = useState("")
  const [editTwitterLink, setEditTwitterLink] = useState("")
  const [editTags, setEditTags] = useState<Tag[]>(["jazzzo"])

  const [filters, setFilters] = useState<string[]>()
  const [filterDirection, setFilterDirection] = useState<"asc" | "desc">("desc")
  const [filterYear, setFilterYear] = useState<number | null>(null)
  const [filterMonth, setFilterMonth] = useState<number | null>(null)

  const [settingsOpen, setSettingsOpen] = useState<boolean>(false)
  const [settings, setSettings] = useState<Settings>(localStorage.getItem("settings") ? JSON.parse(localStorage.getItem("settings")!) : defaultSettings)

  const queryCollection = collection(db, "images")
  const queryOrderBy = orderBy("lastModified", filterDirection)
  const queryLimit = limit(imageLimit)
  const queryFilterTags = where("tags", "array-contains-any", filters)
  const queryFilterFrom = where("lastModified", ">=", new Date(filterYear || 0, (filterMonth) || 0, 1))
  const queryFilterTo = where("lastModified", "<=", new Date(filterYear || 0, (filterMonth) || 11, lastDayOfMonth(filterYear || 0, filterMonth!)))
  const queryStartAfter = startAfter(lastVisible)

  const queryBase = query(queryCollection, queryOrderBy, queryLimit)
  const queryBaseLastVisible = query(queryCollection, queryOrderBy, limit(lastVisibleLength))
  const queryBaseWithDate = filterYear ? query(queryBase, queryFilterFrom, queryFilterTo) : queryBase
  const queryBaseLastVisibleWithDate = filterYear ? query(queryBaseLastVisible, queryFilterFrom, queryFilterTo) : queryBaseLastVisible

  const queryAll = query(queryCollection)
  const queryAllWithDate = filterYear ? query(queryAll, queryFilterFrom, queryFilterTo) : queryAll
  const queryAllWithFilters = filters && filters.length > 0 ? query(queryAllWithDate, queryFilterTags) : queryAllWithDate

  const getImages = async () => {
    const imgs: ImageData[] = []
    const q = filters && filters.length > 0 ? query(queryBaseWithDate, queryFilterTags) : queryBaseWithDate

    const documentSnapshots = await getDocs(q);
    setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1])
    setLastVisibleLength(images.length + documentSnapshots.size > 0 ? images.length + documentSnapshots.size : imageLimit)

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const pushImage = doc.data() as ImageData
        pushImage.id = doc.id
        imgs.push(pushImage)
      })
      setImages(imgs)
    })

    const snapshotAll = await getCountFromServer(queryAllWithFilters);
    setImageCount(snapshotAll.data().count)
  }

  const fetchMore = async () => {
    try {
      const imgs: ImageData[] = [...images]
      const q = filters && filters.length > 0 ? query(queryBaseWithDate, queryFilterTags, queryStartAfter) : query(queryBaseWithDate, queryStartAfter)

      const documentSnapshots = await getDocs(q);
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1])
      setLastVisibleLength(images.length + documentSnapshots.size)

      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let pushImage = doc.data() as ImageData
          pushImage.id = doc.id
          imgs.push(pushImage)
        })
        setImages(imgs)
      })
    }
    catch {
      console.log("No more images")
    }
  }

  const refreshImages = async () => {
    const imgs: ImageData[] = []
    const q = filters && filters.length > 0 ? query(queryBaseLastVisibleWithDate, queryFilterTags) : queryBaseLastVisibleWithDate

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const pushImage = doc.data() as ImageData
        pushImage.id = doc.id
        imgs.push(pushImage)
      })
      setImages(imgs)
    })

    const snapshotAll = await getCountFromServer(queryAllWithFilters);
    setImageCount(snapshotAll.data().count)
  }

  const handleOpenEdit = (image: ImageData) => {
    setEditTitle(image.title || "")
    setEditDescription(image.description || "")
    setEditTwitterLink(image.twitterLink || "")
    setEditTags(image.tags || [])
    setEditImage(image)
    setEditImageIsOpen(true)
  }

  const handleEdit = async () => {
    if (editImage) {
      const ref = doc(db, "images", editImage.id);
      await updateDoc(ref, {
        title: editTitle,
        description: editDescription,
        twitterLink: editTwitterLink,
        tags: editTags,
      }).then(() => {
        refreshImages()
        setEditImageIsOpen(false)
      })
    }
  }

  const updateSettings = (
    key: "showInfo" | "showInfoTitle" | "showInfoTwitterButton" | "showInfoDesc" | "showInfoDate" | "showInfoTags",
    value: any
  ) => {
    const s = { ...settings }
    switch (key) {
      case "showInfo":
        s.info.showInfo = value
        break
      case "showInfoTwitterButton":
        s.info.showInfoTwitterButton = value
        break
      case "showInfoTitle":
        s.info.showInfoTitle = value
        break
      case "showInfoDesc":
        s.info.showInfoDesc = value
        break
      case "showInfoDate":
        s.info.showInfoDate = value
        break
      case "showInfoTags":
        s.info.showInfoTags = value
        break
    }
    setSettings(s)
  }

  const addFilter = (filter: string) => {
    const f = [...filters || []]
    if (!f.includes(filter)) f.push(filter)
    setFilters(f)
  }

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings))
  }, [settings])

  useEffect(() => {
    getImages()
    // eslint-disable-next-line
  }, [filterDirection, filterYear, filterMonth])

  useEffect(() => {
    setAllImagesLoaded(imageCount - images.length <= 0)
  }, [imageCount, images])

  useEffect(() => {
    getImages()

    const auth = getAuth();
    onAuthStateChanged(auth, async (u) => {
      if (u) {
        const docRef = doc(db, "users", u.uid);
        await getDoc(docRef).then(doc => setUser(doc.data() as User));
      }
      else setUser(undefined)
    })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="mb-2 flex flex-col gap-2 justify-start items-start">

        <div className="flex gap-2 flex-wrap">
          <Switcher
            value={galleryDirection}
            onClick={setGalleryDirection}
            options={[{
              value: "ltr", label: <MoreHorizontal size={16} />, tooltip: "Layout left to right"
            }, {
              value: "ttb", label: <MoreVertical size={16} />, tooltip: "Layout top to bottom"
            },]}
          />
          <Switcher
            value={filterDirection}
            onClick={setFilterDirection}
            options={[
              { value: "asc", label: <ArrowUp size={16} />, tooltip: "Ascending" },
              { value: "desc", label: <ArrowDown size={16} />, tooltip: "Descending" },
            ]}
          />
          <Switcher
            value={imageLimit}
            onClick={setImageLimit}
            options={[
              { value: 5, label: "5", tooltip: "Image limit" },
              { value: 10, label: "10", tooltip: "Image limit" },
            ]}
          />
          <Switcher
            options={[{
              label: <RefreshCw size={16} />,
              value: "refresh",
              tooltip: "Refresh",
              toggled: false
            }]}
            value="refresh"
            onClick={() => refreshImages()}
          />

          <Switcher
            options={[{
              label: <img src={LogoX} width={16} height={16} alt="" />,
              value: "x",
              tooltip: "Show X button",
              toggled: settings.info.showInfoTwitterButton
            }]}
            value="Show X Button"
            onClick={() => updateSettings("showInfoTwitterButton", !settings.info.showInfoTwitterButton)}
          />
          <Switcher
            value={settingsOpen}
            onClick={setSettingsOpen}
            options={[{ value: !settingsOpen, label: <IconSettings size={16} />, toggled: settingsOpen, tooltip: "Show more options" }]}
          />
        </div>
          <div className="flex space-x-1">
            <Switcher
              value={filterYear}
              onClick={setFilterYear}
              options={[
                { value: null, label: "All" },
                { value: 2023, label: "2023" },
                { value: 2024, label: "2024" },
              ]}
            />
            {filterYear != null && <Switcher
              value={filterMonth}
              onClick={setFilterMonth}
              options={[
                { value: null, label: "All" },
                { value: 0, label: "Jan" },
                { value: 1, label: "Feb" },
                { value: 2, label: "Mar" },
                { value: 3, label: "Apr" },
                { value: 4, label: "May" },
                { value: 5, label: "Jun" },
                { value: 6, label: "Jul" },
                { value: 7, label: "Aug" },
                { value: 8, label: "Sep" },
                { value: 9, label: "Oct" },
                { value: 10, label: "Nov" },
                { value: 11, label: "Dec" },
              ]}
            />}
          </div>
        {settingsOpen && <div className="w-full flex flex-col space-y-2 p-2 border border-gray-300 rounded-sm">

          <div className="flex items-stretch w-full rounded-sm overflow-hidden">
            <TagsInput
              value={filters || []}
              onChange={setFilters}
              name="tags"
              placeHolder="enter tags"
            />
            <div className="bg-primary bg-noise rounded-r-sm px-4 text-sm cursor-pointer lowercase inline-flex items-center font-semibold hover:bg-opacity-80 transition-colors duration-300 " onClick={getImages}>Search</div>
          </div>
          <div className="border border-gray-300 rounded-sm p-2 max-w-max inline-flex flex-col space-y-1">
            <Toggle
              label="Show Info"
              onClick={() => updateSettings("showInfo", !settings.info.showInfo)}
              toggled={settings.info.showInfo}
            />
            {settings.info.showInfo &&
              <div className="pl-4">
                <Toggle
                  label="Show Title"
                  onClick={() => updateSettings("showInfoTitle", !settings.info.showInfoTitle)}
                  toggled={settings.info.showInfoTitle}
                  small
                />
                <Toggle
                  label="Show Description"
                  onClick={() => updateSettings("showInfoDesc", !settings.info.showInfoDesc)}
                  toggled={settings.info.showInfoDesc}
                  small
                />
                <Toggle
                  label="Show Date"
                  onClick={() => updateSettings("showInfoDate", !settings.info.showInfoDate)}
                  toggled={settings.info.showInfoDate}
                  small
                />
                <Toggle
                  label="Show Tags"
                  onClick={() => updateSettings("showInfoTags", !settings.info.showInfoTags)}
                  toggled={settings.info.showInfoTags}
                  small
                />
              </div>
            }
          </div>
        </div>}
      </div>

      <div className="@container/gallery">
        <div className={`${galleryDirection === "ltr" ? "grid grid-cols-2 @xl/gallery:grid-cols-3 @3xl/gallery:grid-cols-4" : " columns-2 @xl/gallery:columns-3 @3xl/gallery:columns-4"} gap-2 mb-4`}>
          {images && images.length > 0 && images?.map((image, i) => {
            return (
              <div
                key={i}
                className={`overflow-hidden rounded inline-flex flex-col ${settings.info.showInfo ? "border border-gray-300" : ""} ${galleryDirection === "ltr" ? "" : "mb-2"}`}
              >
                <div className={`overflow-hidden relative group ${galleryDirection === "ltr" ? "aspect-square" : ""}`}>
                  <img
                    alt={image.description || image.title || ""}
                    src={image.thumbUrl}
                    onClick={() => { setGalleryIsOpen(true); setInitialSlide(i) }}
                    className="w-full h-full object-cover cursor-pointer transform hover:scale-105 duration-300 rounded"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = Placeholder;
                    }}
                  />
                  <div className="absolute top-2 right-2 transform duration-300 sm:translate-x-20 group-hover:translate-x-0 transition-transform flex space-x-2 items-center">
                    {user?.isAdmin && <div className="bg-surface p-1 hover:scale-110 transition-transform rounded-sm cursor-pointer" onClick={() => { handleOpenEdit(image) }}>
                      <Edit size={16} />
                    </div>}
                    {image.twitterLink && settings.info.showInfoTwitterButton &&
                      <a href={image.twitterLink} target="_blank" rel="noreferrer" className="bg-surface p-1 hover:scale-110 transition-transform rounded-sm cursor-pointer">
                        <img src={LogoX} className="w-4 h-4" alt="X Logo" />
                      </a>
                    }
                  </div>
                </div>
                {settings.info.showInfo &&
                  <div className="p-1 flex flex-col space-y-1 flex-1">
                    <div className="flex flex-col space-y-0.5">
                      {settings.info.showInfoTitle && <h4 className="font-semibold text-sm">{image.title}</h4>}
                      {settings.info.showInfoDesc && <p className="text-xs">{image.description}</p>}
                      {settings.info.showInfoDate && <p className="text-xs text-gray-500">{formattedDate(image.lastModified.toDate())}</p>}
                    </div>

                    {settings.info.showInfoTags && image.tags && image.tags.length > 0 && <>
                      <div className=" flex-1 !m-0" />
                      <div className="flex flex-wrap items-center gap-1 border-t border-gray-300 pt-1">
                        {image.tags?.map(tag => {
                          return (
                            <div
                              key={`tag-${tag}`}
                              onClick={() => { addFilter(tag); setSettingsOpen(true) }}
                              className="text-xs bg-primary bg-noise px-2 py-0.5 cursor-pointer"
                            >{tag}</div>
                          )
                        })}
                      </div></>}
                  </div>
                }
              </div>
            )
          })}
        </div>
        <div className="flex items-center justify-center">
          <Button onClick={fetchMore} disabled={allImagesLoaded}>{allImagesLoaded ? "no more images" : "load more"}</Button>
        </div>
      </div>

      <Overlay full isOpen={galleryIsOpen} setIsOpen={setGalleryIsOpen}>
        <Slider images={images} initialSlide={initialSlide} loadMore={fetchMore} />
      </Overlay>

      {user?.isAdmin && editImage && <Overlay isOpen={editImageIsOpen} setIsOpen={setEditImageIsOpen}>
        <div className="w-full flex space-x-4">
          <div className="w-1/2">
            <img src={editImage?.thumbUrl} alt="" className=" w-full h-auto rounded-sm" />
          </div>
          <div className="w-1/2 flex flex-col space-y-4">

            <EditSection label="Title">
              <input type="text" name="title" onChange={(e) => setEditTitle(e.target.value)} value={editTitle} />
            </EditSection>
            <EditSection label="Twitter Link">
              <input type="text" name="twitterLink" onChange={(e) => setEditTwitterLink(e.target.value)} value={editTwitterLink} />
            </EditSection>
            <EditSection label="Description">
              <textarea name="description" onChange={(e) => setEditDescription(e.target.value)} value={editDescription}></textarea>
            </EditSection>
            <EditSection label="Tags">
              <TagsInput
                value={editImage.tags}
                onChange={setEditTags}
                name="tags"
                placeHolder="enter tags"
              />
            </EditSection>
            <Button onClick={handleEdit} disabled={!user?.isAdmin}>Update</Button>
          </div>
        </div>
      </Overlay>}
    </>
  )
}

export default Gallery

const EditSection = ({ label, children }: { label: string, children: ReactNode }) => (
  <div className='w-full'>
    <div className=" font-semibold mb-1">{label}</div>
    {children}
  </div>
)