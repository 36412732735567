
import { ReactNode } from "react"
import LogoX from "../assets/logo-x.svg"
import { xURL } from "../helper/urlHelper"

const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-300 bg-noise px-2">
      <div className="max-w-5xl mx-auto flex justify-between items-center py-2">
        <div className=""> </div>
        <div className="flex items-center space-x-2">
          <FooterIcon url={xURL}><img src={LogoX} width={16} height={16} alt="" /></FooterIcon>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const FooterIcon = ({
  url,
  children,
}: {
  url: string
  children: ReactNode
}) => <a href={url} target="_blank" rel="noreferrer">{children}</a>