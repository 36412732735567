import { Timestamp } from "firebase/firestore";

export interface ImageData {
  id: string;
  imageUrl: string;
  thumbUrl: string,
  storageRef: string;
  tags: Tag[];
  lastModified: Timestamp;
  title?: string;
  description?: string;
  twitterLink?: string;
}

export type Tag = string

export interface Settings {
  info: {
    showInfo: boolean
    showInfoTwitterButton: boolean
    showInfoTitle: boolean
    showInfoDesc: boolean
    showInfoDate: boolean
    showInfoTags: boolean
  }
}

export const defaultSettings: Settings = {
  info: {
    showInfo: false,
    showInfoTwitterButton: true,
    showInfoTitle: true,
    showInfoDesc: false,
    showInfoDate: true,
    showInfoTags: false,
  }
}

export interface User {
  isAdmin: boolean
}