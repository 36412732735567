import { addDoc, collection, doc, getDoc, Timestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { ReactNode, useEffect, useState } from 'react';
import { TagsInput } from 'react-tag-input-component';
import Button from "../components/Button";
import Container from "../components/Container";
import ProgressBar from "../components/ProgressBar";
import { db, storage } from '../firebase/firebase';
import { resizeFile } from "../helper/imageHelper";
import { Tag, User } from '../types';

import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";



const Upload = () => {

  const [user, setUser] = useState<User>()

  const [image, setImage] = useState<any>("");
  const [thumbnail, setThumbnail] = useState<any>()

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [twitterLink, setTwitterLink] = useState("")
  const [tags, setTags] = useState<Tag[]>(["jazzzo"])

  const [progress, setProgress] = useState(0);
  const [progressThumb, setProgressThumb] = useState(0)

  const handleChange = async (event: any) => {
    try {
      const file = event.target.files[0];
      const thumb = await resizeFile(file, 300);
      //const fileResized = await resizeFile(file, file.width)
      setThumbnail(thumb)
      setImage(file);
    } catch (err) {
      console.log(err)
    }
  }

  const handleUpload = () => {
    if (!image || !thumbnail) {
      alert("Please upload an image first!")
      return
    }

    const storageRef = ref(storage, `/images/${image.name}`)
    const thumbRef = ref(storage, `/images/${image.name}_thumb`)

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, image)
    const uploadTaskThumb = uploadBytesResumable(thumbRef, thumbnail)

    uploadTaskThumb.on("state_changed", (snapshotThumb) => {
      const percent = Math.round(
        (snapshotThumb.bytesTransferred / snapshotThumb.totalBytes) * 100
      )
      setProgressThumb(percent)
    },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTaskThumb.snapshot.ref).then(async (thumbUrl) => {
          uploadTask.on("state_changed", (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            setProgress(percent)
          },
            (err) => console.log(err),
            () => {
              // download url
              getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {

                await addDoc(collection(db, "images"), {
                  imageUrl: url,
                  thumbUrl: thumbUrl,
                  storageRef: `/images/${image.name}`,
                  tags: tags,
                  lastModified: Timestamp.fromMillis(image.lastModified),
                  title: title,
                  description: description,
                  twitterLink: twitterLink,
                }).then(() => {
                  setTitle("")
                  setImage(null)
                  setThumbnail(null)
                  setDescription("")
                  setTwitterLink("")
                  setProgress(0)
                  setProgressThumb(0)
                  setTags(["jazzzo"])
                });
              });
            }
          );
        });
      }
    )
  };

  const login = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
      }).catch((error) => {
        console.log(error.code, error.message)
      });
  }

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (u) => {
      if (u) {
        const docRef = doc(db, "users", u.uid);
        await getDoc(docRef).then(doc => setUser(doc.data() as User));
      }
      else setUser(undefined)
    })
  }, [])

  return (
    <>
      {user?.isAdmin && <Container>
        <div className='flex space-x-4 mx-auto w-full max-w-screen-lg py-8'>
          <div className='border border-gray-200 rounded p-4 w-1/2'>
            <input type="file" onChange={handleChange} accept="/image/*" />
            {image && <img src={URL.createObjectURL(image)} className="max-w-full mt-4" alt="" />}
            {thumbnail && <img src={URL.createObjectURL(thumbnail)} width={thumbnail.width} height={thumbnail.height} className="mt-4" alt="" />}
          </div>
          <div className='border border-gray-200 rounded p-4 flex flex-col justify-start items-start space-y-4 flex-1'>
            <UploadSection label="Title">
              <input type="text" name="title" onChange={(e) => setTitle(e.target.value)} value={title} />
            </UploadSection>
            <UploadSection label="Twitter Link">
              <input type="text" name="twitterLink" onChange={(e) => setTwitterLink(e.target.value)} value={twitterLink} />
            </UploadSection>
            <UploadSection label="Description">
              <textarea name="description" onChange={(e) => setDescription(e.target.value)} value={description}></textarea>
            </UploadSection>
            <UploadSection label="Tags">
              <TagsInput
                value={tags}
                onChange={setTags}
                name="tags"
                placeHolder="enter tags"
              />
            </UploadSection>
            <Button onClick={handleUpload} disabled={!user}>Upload</Button>
            <ProgressBar progress={progress} />
            <ProgressBar progress={progressThumb} />
          </div>
        </div>
      </Container>}
      {!user && <Container>
        <Button onClick={login}>Login</Button>
      </Container>}
    </>
  );
}

export default Upload;

const UploadSection = ({ label, children }: { label: string, children: ReactNode }) => (
  <div className='w-full'>
    <div className=" font-semibold mb-1">{label}</div>
    {children}
  </div>
)