import { useState } from "react";
import { ChevronLeft, ChevronRight, Info, X } from "react-feather";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/keyboard";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { formattedDate } from "../helper/dateHelper";
import { ImageData } from "../types";
import LogoX from "../assets/logo-x.svg"

const Slider = ({
  images,
  initialSlide = 1,
  loadMore,
}: {
  images: ImageData[]
  initialSlide?: number
  loadMore: () => void
}) => {
  const [activeImage, setActiveImage] = useState<ImageData>(images[initialSlide])
  const [infoOpen, setInfoOpen] = useState(false)

  return (
    <>
      <Swiper
        className="h-full w-full"
        // @ts-ignore
        modules={[Pagination, Navigation, Keyboard]}
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        initialSlide={initialSlide}
        keyboard
        pagination={{
          type: "fraction",
          el: ".pagination",
          renderFraction(currentClass: any, totalClass: any) {
            return `<span class="${currentClass}"></span><span class="mx-2">of</span><span class="${totalClass}"></span>`;
          },
        }}
        onSlideChange={s => setActiveImage(images[s.activeIndex])}
        onReachEnd={loadMore}
      >
        {images.map((image, i) => {
          return (
            <SwiperSlide key={i} className="inline-flex items-center justify-center">
              <img alt={image.title || ""} src={image.imageUrl} className="max-w-full max-h-full w-auto h-auto" />
            </SwiperSlide>
          )
        })}
      </Swiper>

      <NavigationButton direction="prev" />
      <NavigationButton direction="next" />


      {!infoOpen && <div className="absolute right-2 bottom-2 z-10 cursor-pointer" onClick={() => setInfoOpen(true)}><Info size={24} /></div>}
      {infoOpen && <div className="absolute right-2 bottom-2 z-10 p-2 bg-surface bg-noise bg-opacity-80 border border-gray-400 rounded-sm max-w-[300px] inline-flex flex-col space-y-1">
        <div className="self-end cursor-pointer" onClick={() => setInfoOpen(false)}><X size={16} /></div>
        {activeImage.title && <div className="font-semibold">{activeImage.title}</div>}
        {activeImage.description && <div className="text-sm">{activeImage.description}</div>}
        {activeImage.lastModified && <div className="text-sm text-gray-500">{formattedDate(activeImage.lastModified.toDate())}</div>}
        {activeImage.twitterLink && <a href={activeImage.twitterLink} target="_blank" rel="noreferrer"><img src={LogoX} width={16} height={16} alt="" /></a>}
        {activeImage.tags && <div className="flex flex-wrap gap-1 pt-1 border-t border-gray-400">{activeImage.tags.map(tag => <div className="bg-gray-300 px-1 rounded-sm text-xs" key={tag}>{tag}</div>)}</div>}
      </div>}

      <div
        className="
          pagination bg-primary bg-noise py-2 px-4 text-sm rounded-sm
          inline-block fixed bottom-2 left-3 transform z-10
          
          before:content-[''] before:absolute before:h-full before:w-full before:border before:border-textColor before:rounded-sm
          before:-left-1 before:bottom-1 
        "
      >
        <div className="pagination-divider">|</div>
      </div>
    </>
  )
}

const NavigationButton = ({
  direction,
  reachedEnd,
}: {
  direction: "prev" | "next"
  reachedEnd?: boolean
}) => {
  return (
    <div
      className={`
        absolute text-dark cursor-pointer top-1/2 transform -translate-y-1/2 z-20 text-textColor rounded border-opacity-30 border border-textColor bg-white bg-opacity-30 p-4 transition-all duration-300
        ${direction === "prev" ? "left-2" : "right-2"}
        ${direction === "prev" ? "sm:hover:pl-8" : "sm:hover:pr-8"}
        ${direction}
    `}
    >
      {direction === "prev" ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
    </div>
  );
};


export default Slider