import Container from "../components/Container";
import Gallery from "../components/Gallery";

const Home = () => {
  return (
    <Container>
      <Gallery />
    </Container>
  )
}

export default Home; 