import { useEffect, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { Route, Routes, useLocation } from 'react-router';
import Button from './components/Button';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Upload from './pages/Upload';

function App() {
  const [showScrollUp, setShowScrollUp] = useState(false);
  //const [ads, setAds] = useState(false)

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    window.onscroll = function () {
      window.scrollY > window.innerHeight
        ? setShowScrollUp(true)
        : setShowScrollUp(false);
    };
  });

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };


  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      {showScrollUp && (
        <Button
          //className="!fixed bottom-4 right-4 group z-10"
          onClick={scrollToTop}
        >
          <ChevronUp className="w-4 h-4 group-hover:transform group-hover:-translate-y-0.5 transition-transform duration-300" />
        </Button>
      )}
    </>
  )
}

export default App;
