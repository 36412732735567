import { ReactNode } from "react"

const Button = ({
  children,
  onClick,
  disabled,
}: {
  children: ReactNode
  onClick: () => void
  disabled?: boolean
}
) => {
  return (
    <button
      className={`
      ${disabled ? "bg-gray-400 cursor-not-allowed" : "bg-primary"} bg-noise transition-all duration-300 rounded-sm pt-2 pb-3 px-8 text-sm cursor-pointer lowercase inline-flex space-x-2 justify-center items-center font-semibold relative
      before:content-[''] before:w-full before:h-full before:rounded-sm before:border before:border-textColor before:absolute before:-left-1 before:-top-1 before:pointer-events-none 
      active:before:-top-0.5 active:before:-left-0.5 before:transition-all before:duration-200
      hover:shadow-[0px_0px_10px_0_rgba(0,0,0,0.)]
      `}
      onClick={onClick}
      disabled={disabled}
    >{children}</button>
  )
}

export default Button