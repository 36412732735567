//const locale = Intl.DateTimeFormat().resolvedOptions().locale;
const locale = "de-DE";

export const formattedDateFull = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(timestamp));
};

export const formattedDate = (timestamp: number | Date): string => {
  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(timestamp));
};

export const lastDayOfMonth = (year: number, month: number): number => new Date(year, month + 1, 0).getDate()