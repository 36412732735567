import { getAuth, signOut } from "firebase/auth";


export const logOut = () => {

  const auth = getAuth();
  signOut(auth).then(() => {
    console.log("log out successful")
  }).catch((error) => {
    console.log(error)
  });
}