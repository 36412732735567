import { Dispatch, ReactNode, SetStateAction } from "react"
import { Tooltip } from "react-tooltip"


export const Switcher = ({
  options,
  value,
  small = false,
  onClick,
}: {
  options: SwitcherOption[]
  value: any
  small?: boolean
  onClick: Dispatch<SetStateAction<any>>
}) => {
  return (
    <div className='inline-flex flex-wrap cursor-pointer text-xs sm:text-sm rounded overflow-hidden bg-gray-300 p-0.5'>
      {options.map(option => <SwitcherButton key={option.value} option={option} toggled={option.toggled || option.value === value} onClick={onClick} />)}
    </div>
  )
}

export default Switcher

export interface SwitcherOption {
  value: any
  label: string | ReactNode
  toggled?: boolean
  tooltip?: string
}


const SwitcherButton = ({
  option,
  toggled,
  onClick,
}: {
  option: SwitcherOption
  toggled: boolean
  onClick: Dispatch<SetStateAction<any>>
}) => (
  <>
    <span data-tooltip-id={`tooltip-${option.value}`} className={`inline-flex items-center py-1 px-2 transition-colors duration-200 rounded-sm bg-noise ${toggled ? 'bg-primary font-bold' : ''}`} onClick={() => onClick(option.value)}>
      {option.label}
    </span>
    {option.tooltip && <Tooltip id={`tooltip-${option.value}`} >
      {option.tooltip}
    </Tooltip>}
  </>
)