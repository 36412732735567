import { getAuth, onAuthStateChanged } from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import { ReactNode, useEffect, useRef, useState } from "react"
import { LogOut } from "react-feather"
import { Link } from "react-router-dom"
import { db } from "../firebase/firebase"
import { logOut } from "../helper/userHelper"
import { User } from "../types"

const Header = ({ children }: { children?: ReactNode }) => {
  const [user, setUser] = useState<User>()
  const headerRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const body = document.getElementById('root')
    if (headerRef?.current) {
      const headerHeight = headerRef.current.clientHeight
      if (body) body.style.paddingTop = headerHeight + 16 + "px"
    }

    const auth = getAuth();
    onAuthStateChanged(auth, async (u) => {
      if (u) {
        const docRef = doc(db, "users", u.uid);
        await getDoc(docRef).then(doc => setUser(doc.data() as User));
      }
      else setUser(undefined)
    })
  }, [])

  return (
    <header ref={headerRef} className="bg-primary bg-noise shadow flex justify-center fixed top-0 left-0 right-0 z-30">
      <div className=" max-w-5xl w-full flex items-center justify-between px-4 py-2">
        <div className="uppercase text-xl font-bold text-primary">under construction</div>
        <div className="">{children}</div>
        {user && <div className="flex space-x-2 items-center">
          {user?.isAdmin && <Link to={"upload"} className="cursor-pointer bg-gray-300 bg-noise py-1 px-2 rounded-sm text-sm">Upload</Link>}
          <div className="cursor-pointer bg-gray-300 bg-noise p-1 rounded-sm" onClick={logOut}><LogOut size={14} /></div>
        </div>}
      </div>
    </header>
  )
}

export default Header