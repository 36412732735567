const Toggle = ({
  label,
  onClick,
  toggled,
  small = false,
}: {
  label?: string
  onClick: () => void
  toggled: boolean
  small?: boolean
}) => {
  return (
    <label className="toggle flex items-center justify-between relative cursor-pointer select-none py-1 pl-2 even:bg-gray-200 hover:bg-gray-300 transition-colors duration-300" onChange={onClick}>
      {label && <span className={`${!small ? "" : "text-sm"} mr-8 font-semibold`}>{label}</span>}
      <input
        checked={toggled}
        readOnly type="checkbox"
        className={`appearance-none transition-colors cursor-pointer ${!small ? "w-10 h-5" : "w-6 h-3"} rounded-full focus:outline-none ${toggled ? "bg-primary" : "bg-gray-300"}`}
      />
      <span className={`${!small ? "w-5 h-5 right-5" : "w-3 h-3 right-3"} absolute rounded-full transform transition-transform bg-white ${toggled ? !small ? "translate-x-5" : "translate-x-3" : ""}`} />
    </label>
  )
}

export default Toggle